import { z } from 'zod'

import { type EnsureExactKeys } from '@src/ts-utils/types/utilTypes'

import { type LocalMapStepKeys } from './type'

/**
 * 새로운 스텝 추가시
 * 1. {stepName}StepParamsSchema zod Schema 생성
 * 2. StepParamsTypeMap에 추가
 *
 * 새로운 param 추가시
 * 1. 스탭 스키마에 param zode type 추가
 */

export const SearchQueryFrom = [
  'recent',
  'category_suggestion',
  'suggested',
  'recommendation_chip',
  'typed',
  'launchpad',
  'unknown',
] as const

export const CommonParamsSchema = z.object({
  mapBounds: z.string().nullable().optional(),
})

export const HomeStepParamsSchema = z
  .object({
    selectedPin: z.string().nullable().default(null),
    subStepType: z.enum(['contribution']).optional(),
  })
  .merge(CommonParamsSchema)

export const SearchTypingStepParamsSchema = z
  .object({
    query: z.string().nullable().default(null),
  })
  .merge(CommonParamsSchema)

export const SearchStepParamsSchema = z
  .object({
    query: z.string().nullable().default(null),
    selectedPin: z.string().nullable().default(null),
    queryFrom: z.enum(SearchQueryFrom).default('unknown'),
    isBoundsSearch: z.boolean().default(false).optional(),
  })
  .merge(CommonParamsSchema)

export const PreviewStepParamsSchema = z
  .object({
    localProfileId: z.string(),
  })
  .merge(CommonParamsSchema)

export const SearchedPreviewStepParamsSchema = SearchStepParamsSchema.merge(
  PreviewStepParamsSchema
)

export const StoryPreviewStepParamsSchema = z.object({
  storyId: z.string(),
  actionSheet: z.union([z.literal('writer'), z.literal('viewer')]).optional(),
})

export type StepParamsTypeMap = EnsureExactKeys<
  {
    home: z.infer<typeof HomeStepParamsSchema>
    searchDiscovery: z.infer<typeof SearchTypingStepParamsSchema>
    searchResult: z.infer<typeof SearchStepParamsSchema>
    preview: z.infer<typeof PreviewStepParamsSchema>
    searchedPreview: z.infer<typeof SearchedPreviewStepParamsSchema>
    storyPreview: z.infer<typeof StoryPreviewStepParamsSchema>
  },
  LocalMapStepKeys
>
